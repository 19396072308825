<template>
  <div class="list-li">
    <div class="li-label">{{ label }}</div>
    <div class="li-value">{{ value }}</div>
  </div>
</template>

<script>
export default {
  props: {
    liLabel: String,
    liValue: String | Number,
    valueArr: Array,
  },

  computed: {
    label() {
      return this.liLabel;
    },
    value() {
      let temp = this.liValue;

      if (this.valueArr) {
        this.valueArr.forEach((e) => {
          e.value === this.liValue ? (temp = e.label) : null;
        });
      }
      
      return temp;
    },
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.list-li {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  min-height: 20px;
  margin-bottom: 12px;
  line-height: 32px;
}
.li-label {
  box-sizing: border-box;
  width: 100px;
  min-height: 20px;
  color: #cccccc;
}
.li-value {
  box-sizing: border-box;
  width: 80%;
  min-height: 20px;
  padding-left: 10px;
  color: #000000;
}
</style>
