<template>
  <div class="layout-main">
    <div class="layout-table-basic">
      <div class="toolbar">
        <div class="search">
          <el-form :inline="true">
            <el-form-item label="管理员ID/姓名/手机号 ：">
              <el-input size="small" placeholder="请输入管理员ID/姓名/手机号" v-model="filterData.keyword" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button size="small" @click="handleSearchReset">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="handle">
          
        </div>
      </div>

      <div class="table">
        <el-table v-loading="isTableloading" :data="tableData" stripe highlight-current-row style="width: 100%" height="100%" @row-click="handleDrawerDetailVisible">
          <el-table-column prop="" label="" width="10"> </el-table-column>
          <el-table-column prop="id" label="ID" width="80"> </el-table-column>
          <el-table-column prop="adminId" label="管理员ID" width="100"> </el-table-column>
          <el-table-column prop="realname" label="姓名" min-width="60"> </el-table-column>
          <el-table-column prop="logTitle" label="操作内容" min-width="100"> </el-table-column>
          <el-table-column prop="createtime" label="操作时间" min-width="100"> </el-table-column>
          <el-table-column prop="params" label="参数" min-width="200"> </el-table-column>
          <el-table-column label="操作" fixed="right" width="200"> </el-table-column>
        </el-table>
      </div>

      <div class="pagination">
        <el-pagination :page="searchParams.page" :page-size="searchParams.limit" :total="total" background layout="total, sizes, prev, pager, next" @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
      </div>

      <!-- 列表详情，抽屉组件 -->
      <el-drawer title="详情" :visible.sync="drawerDetailVisible">
        <div class="layout-drawer-handle"></div>
        <div class="layout-drawer-detail">
          <div class="layout-drawer-detail-top">
            <div class="layout-drawer-detail-top-title">
              基础信息
            </div>
          </div>
          <div class="layout-drawer-detail-main">
            <div class="layout-drawer-detail-list">
              <DrawerDetail liLabel="管理员ID" :liValue="drawerDetailObj.adminId"></DrawerDetail>
              <DrawerDetail liLabel="姓名" :liValue="drawerDetailObj.realname"></DrawerDetail>
              <DrawerDetail liLabel="操作内容" :liValue="drawerDetailObj.logTitle"></DrawerDetail>
              <DrawerDetail liLabel="操作时间" :liValue="drawerDetailObj.createtime"></DrawerDetail>
              <DrawerDetail liLabel="参数" :liValue="drawerDetailObj.params"></DrawerDetail>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import Request from '@/api/log';

import DrawerDetail from '../../../layout/console/components/DrawerDetail.vue';
export default {
  components: {
    DrawerDetail,
  },

  data() {
    return {
      //列表相关
      tableData: null,
      isTableloading: false,
      total: 0,

      //查询区域字段
      filterData: {
        keyword: '',
      },

      //发送查询请求字段
      searchParams: {
        limit: 10,
        page: 1,
      },

      drawerDetailVisible: false,
      drawerDetailObj: {},
    };
  },

  mounted() {
    this.getTableList();
  },

  methods: {
    getTableList() {
      this.isTableloading = true;
      Request.page(this.searchParams).then((res) => {
        console.log(res);
        this.isTableloading = false;
        if (res && res.code === 200) {
          if (res.data.list.length > 0) {
            this.renderTable(res.data.list);
          } else {
            if (this.searchParams.page > 1) {
              this.searchParams.page--;
              this.getTableList();
            } else {
              this.renderTable(res.data.list);
            }
          }
          this.total = res.data.total;
        }
      });
    },

    // 渲染表格
    renderTable(data) {
      this.tableData = [];
      data.forEach((e) => {
        this.tableData.push(e);
      });
    },

    //查询搜索按钮
    handleSearch() {
      for (let key in this.filterData) {
        this.searchParams[key] = this.filterData[key];
      }

      for (let key in this.searchParams) {
        if (!this.searchParams[key]) {
          delete this.searchParams[key];
        }
      }

      this.searchParams.page = 1;
      this.getTableList();
    },

    //查询重置按钮
    handleSearchReset() {
      for (let key in this.filterData) {
        this.filterData[key] = '';
      }
      
      this.handleSearch();
    },

    //分页改变每页显示数量
    handleSizeChange(val) {
      this.searchParams.page = 1;
      this.searchParams.limit = val;
      this.getTableList();
    },

    //分页跳转至当前页
    handleCurrentChange(val) {
      this.searchParams.page = val;
      this.getTableList();
    },

    //添加按钮
    handleExport() {
      let parms = {};
      for (let key in this.filterData) {
        if (this.filterData[key]) {
          parms[key] = this.filterData[key];
        }
      }

      Request.excelall(parms).then((res) => {
        window.open(`http://localhost:8080/api/user-bids/excelall?userKeyword=${this.filterData.userKeyword}&carKeyword=${this.filterData.carKeyword}&salesId=${this.filterData.salesId}&bidStatus=${this.filterData.bidStatus}`);

        // let downloadLoading = this.$loading({ background: 'rgba(0, 0, 0, 0.6)' });
        // const blob = new Blob([res.data], {
        //   type: 'application/vnd.ms-excel;charset=utf-8',
        // });
        // const objectUrl = URL.createObjectURL(blob);
        // const a = document.createElement('a');
        // a.style.display = 'none';
        // a.href = objectUrl; // 指定下载链接
        // a.download = '投标信息'; // 指定下载文件名
        // a.click();
        // URL.revokeObjectURL(a.href); // 释放URL对象
        // downloadLoading.close();
      });
    },

    //中标
    handleWinConfirm(row) {
      let loading = this.$loading({ background: 'rgba(0, 0, 0, 0.6)' });

      Request.win(row.id).then((res) => {
        if (res && res.code == 200) {
          this.msgSuccess('操作成功');
          loading.close();
          this.getTableList();

          if (this.drawerDetailVisible) this.drawerDetailVisible = false;
        }
      });
    },

    //点击列表row右侧弹出详情框
    handleDrawerDetailVisible(row) {
      this.drawerDetailObj = row;
      this.drawerDetailVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-select .el-input.is-disabled .el-input__inner {
  height: 22px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  color: #606266;
  cursor: initial;
}

::v-deep .el-input.is-disabled .el-input__icon {
  display: none;
}

::v-deep .el-select .el-input.is-disabled .el-input__inner {
  cursor: text;
  background-color: initial;
  border: 0;
  padding-left: 0;
  color: initial;
}
::v-deep .el-input.is-disabled .el-input__icon {
  display: none;
}
</style>
