import axios from './index';
export default {
  // 获取所有
  listAll: () => {
    return axios.get('/admin-operate-log/listall');
  },

  // 分页查询
  page: (params) => {
    return axios.get('/admin-operate-log/listpage', { params });
  },

  // // 根据ID获取
  // getById: (id) => {
  //   return axios.get(`/admin-operate-log/${id}`);
  // },

  // 新增
  create: (data) => {
    return axios.post('/admin-operate-log', data);
  },

  // 编辑
  update: (data) => {
    return axios.put('/admin-operate-log', data);
  },

  // 删除
  remove: (id) => {
    return axios.delete(`/admin-operate-log/${id}`);
  },
};
